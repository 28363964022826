import React from 'react';
import { Container, Button } from 'react-bootstrap';
import './About.css';
import wall1 from './assets/wall1.jpg';

const About = () => {

  return (
    <div className="about-container" style={{ backgroundImage: `url(${wall1})` }}>
      <div className="overlay">
        <Container>
          <h1 className='titulo-about'>C.E. INIA Los Tilos</h1>
          <p className='parrafo-about'>
          En el SmartField INIA Los Tilos, ubicado en Buin, Región Metropolitana, se integran diversas tecnologías específicamente adaptadas al contexto sociocultural y productivo de la zona centro, 
          enfocándose principalmente en la fruticultura mayor. Este campo busca fomentar un ecosistema colaborativo comercial donde las empresas locales y extranjeras pueden mejorar la articulación 
          de la oferta y demanda, incluyendo la Agricultura Familiar Campesina (AFC). Además, se ha completado la validación de tecnologías como drones, sensores de riego y sistemas de fumigación diferenciada, 
          entre otros.
          </p>
          
          <h1 className='titulo-about'>C.E. INIA Santa Rosa</h1>
          <p className='parrafo-about'>
          Por otro lado, en la macrozona centro sur (Maule, Ñuble y Biobío), caracterizada por la agricultura de frutales menores, semilleros y cultivos industriales, el SmartField Centro Sur de INIA tiene como 
          objetivo fundamental atraer y crear empresas tecnológicas que agreguen valor a los productores locales. Al adoptar tecnologías del Agro 4.0, se busca incrementar la producción, reducir costos y generar 
          mayores ingresos para los agricultores de la región.
          </p>
        </Container>
      </div>
    </div>
  );
};

export default About;