import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Us from './pages/Us';
import Smartfield from './pages/Smartfield';
import INDAP from './pages/INDAP';
import Agroconecta from './pages/Agroconecta';
import Campus from './pages/Campus';
import NavigationBar from './components/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
import Footer from './components/Footer';
import Events from './pages/Events';
import Library from './pages/Library';

const App = () => {
   return (
      <Router>
           <NavigationBar />
            <Routes>
                <Route path="/" element={<Home />} exact /> {/* Exact para evitar conflictos */}
                <Route path="/us" element={<Us />} />
                <Route path="/smartfield" element={<Smartfield />} />
                <Route path="/indap" element={<INDAP />} />
                <Route path="/agroconecta" element={<Agroconecta />} />
                <Route path="/campus" element={<Campus />} />
                <Route path="/events" element={<Events />} />
                <Route path="/library" element={<Library />} />
                {/* Ruta para manejar errores 404 */}
                <Route path="*" element={<div>404 Not Found</div>} />
            </Routes>

           <Footer />
       </Router>
   );
};

export default App;